import Inputmask from 'inputmask';

class Input {
	constructor(el, selector) {
		if (!el) return false;

		this.root = el;
		this.type = this.root.dataset.type || this.root.type;

		if (this.root.hasAttribute(`${selector}-inited`)) return false;
		this.root.setAttribute(`${selector}-inited`, true);

		this.error = this.root.parentNode.querySelector('[data-error]');

		this._matchHTML(selector);
		this.init();
	}

	_matchHTML(selector) {

	}

	init() {
		this._initMasks();
		this._addListeners();
		this._checkChange();
	}

	_addListeners() {
		this.root.addEventListener('focus', e => {
			this.root.parentNode.classList.add('is-focused');
		});

		this.root.addEventListener('blur', e => {
			this.root.parentNode.classList.remove('is-focused');
			this._checkChange();
		});

		this.root.addEventListener('input', e => {
			this._checkChange();
		})
	}

	_checkChange() {
		let val = this.root.value;

		if (val) {
			this.root.parentNode.classList.add('is-changed');
		} else {
			this.root.parentNode.classList.remove('is-changed');
		}
	}

	_initMasks() {

		if(this.root.hasAttribute('data-inputmask-regex')) {
			Inputmask().mask(this.root);
		}

		if (this.type !== 'tel') return false;

		Inputmask.extendDefinitions({
			"X": {
				validator: function(chrs) {
					if (chrs == 8 || chrs == 7) {
						return { c: 7, caret: 4 };
					} else if (chrs == 9) {
						return { insert: [{ pos: 1, c: 7 }, { pos: 4, c: 9 }], caret: 5 }
					} else if (parseInt(chrs)) {
						return true;
					} else {
						return false;
					}
				}
			},
			"Y": {
				validator: function (chrs, buffer, pos, strict, opts) {
					if(chrs == 9) {
						return { c: chrs, caret: 6 }
					}
					if(chrs == 0) {
						return { c: 0, caret: 6 }
					} else if (buffer.buffer[pos-1] == 9 && buffer.buffer[pos-6] != 3) {
						return true;
					} else {
						return { c: chrs, caret: 10 }
					}
				}
			}
		});
		Inputmask.extendAliases({
			"tel": {
				mask: "+X[99] (9Y[9]) 999-99-99",
				jitMasking: 1,
				clearMaskOnLostFocus: true,
				showMaskOnHover: false,
			}
		});

		Inputmask("tel").mask(this.root);
	}

	getName() {
		return this.root.name;
	}

	setValue(value) {
		if (this.type === 'textarea') {
			this.root.innerText = value;
		} else if (this.type === 'radio') {
			this.root.checked = this.root.value === value;
		} else {
			this.root.value = value;
		}

		this.root.dispatchEvent(new Event('input'));
	}

	setError(text) {
		this.root.parentNode.querySelector('[data-error]').innerHTML = text;
	}

	reset() {
		this.setValue('');
	}

}

class InputFactory {
	constructor(props) {
		this.inputs = [];

		[].slice.call(document.querySelectorAll(`[${props.selector}]`)).forEach(el => {
			this.inputs.push(
				new Input(el, props.selector)
			);
		});
	}
}

export {Input, InputFactory}
