import Cookies from 'js-cookie';

export default class Translator {

	constructor(params) {

        this.btn = document.querySelector('[data-translator-btn]');

		this.options = {
				en: {
					title: 'EN',
					text: 'Английская версия сайта'
				},
				ru: {
					title: 'РУС',
					text: 'Russian version of the site'
				}
			};

	}

	init() {

        this._btnInit();

		Translator._elementsInit();

		this.btnHandler();

		Translator.translate();

	}

    _btnInit() {
        if(!this.btn) return false;

		let lang = this.options.en;

		if(Cookies.get('lang') === 'en') lang = this.options.ru;

		this.changeBtnState(lang);

    }

	static _elementsInit() {

		let elements = [].slice.call(document.querySelectorAll('[data-translate]'));

		if(!elements.length) return false;

		elements.forEach(element => {
			if(element.getAttribute('data-translate-source')) return false;
			element.setAttribute('data-translate-source', element.innerHTML);
		});

	}

	changeBtnState(lang) {
		this.btn.innerHTML = lang.title;
		this.btn.setAttribute('alt', lang.title);
		this.btn.setAttribute('title', lang.text);
		this.btn.classList.add('on');
	}

	btnHandler() {
		if(!this.btn) return false;

		this.btn.addEventListener('click', e => {
			e.preventDefault();

			if(Cookies.get('lang') === 'en') {
				Cookies.remove('lang');
			} else {
				Cookies.set('lang', 'en');
			}

			this._btnInit();

			Translator.translate();

		});

	}

	static translate() {

		let logotype = document.querySelector('[data-logotype]');
		if(logotype) {
			if(Cookies.get('lang') === 'en') {
				logotype.classList.add('en');
			} else {
				logotype.classList.remove('en');
			}
		}

		let elements = [].slice.call(document.querySelectorAll('[data-translate]'));

		if(!elements.length) return false;

		elements.forEach(element => {

			if(Cookies.get('lang') === 'en') {
				element.innerHTML = element.getAttribute('data-translate');
			} else {
				element.innerHTML = element.getAttribute('data-translate-source');
			}

		});

	}

}
