import $ from 'jquery';
import loader from '../loader/loader';
import Modal from '../modal/modal';

import Translator from '../translator/translator';

require('intersection-observer');

export default class Main {

	constructor(params) {

		this.cover = document.querySelector('[data-cover]');
		this.callback = document.querySelector('[data-callback]');
		this.contentRows = [].slice.call(document.querySelectorAll('[data-main-content-row]'));

		this.callbackBtn = document.querySelector('[data-callback-btn]');

	}

	init() {

        this._coverInit();
		this._callbackInit();
		this._rowsInit();

		this.callbackBtnHandler();

	}

	_coverInit() {
        if(!this.cover) return false;

        let elements = [ 'title', 'sub', 'intro', 'rolls', 'shadow', 'info' ];

        elements.forEach(e => {
            let els = [].slice.call(this.cover.querySelectorAll('[data-' + e + ']'));
            if(!els.length) return false;
            els.forEach( e => {
                if(e.classList.contains('init')) e.classList.remove('init');
            });
        });

	}

	_callbackInit() {
		if(!this.callback) return false;

		const options = { root: null, rootMargin: '0px', threshold: 0.5 };

		const OBSERVER = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
					if(entry.target.classList.contains('init')) {
						entry.target.classList.remove('init');
						OBSERVER.unobserve(entry.target);
					}
                }
            });
        }, options);

		OBSERVER.observe(this.callback);

	}

	_rowsInit() {
		if(!this.contentRows.length) return false;

		const options = { root: null, rootMargin: '0px', threshold: 0.5 };

		const OBSERVER = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
					if(entry.target.classList.contains('init')) {
						entry.target.classList.remove('init');
						OBSERVER.unobserve(entry.target);
					}
                }
            });
        }, options);

		this.contentRows.forEach(row => {
			OBSERVER.observe(row);
		});

	}

	callbackBtnHandler() {
		if(!this.callbackBtn) return false;

		this.callbackBtn.addEventListener('click', event => {
			event.preventDefault();

			$.ajax({
	            url: event.target.getAttribute('href'),
	            dataType: 'html',
	            beforeSend: function() {
	                loader.show();
	            },
	            success: function(res) {
	                loader.hide();
	                let modal = 0
	                modal = $(res).appendTo('body').modal()

					Translator._elementsInit();
					Translator.translate();

	                modal.on($.modal.CLOSE, function(event, m) {
	                    m.elm.remove()
	                });
	                Modal.Init();
	            }
	        })

		});

	}

}
