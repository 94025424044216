'use strict';

let loaderElement = 0

export default class Loader {

    constructor(params) {

	}

	init() {

        this._init()

	}

    _init() {

        loaderElement = document.createElement('div')

        loaderElement.classList.add('loader')
        loaderElement.classList.add('loader--is-hidden')
        loaderElement.classList.add('loader--is-visibility-hidden')

        document.body.appendChild(loaderElement)

    }

    static show() {

        document.body.classList.add('no-scroll')
        loaderElement.classList.remove('loader--is-hidden')
        //setTimeout(function() {
            loaderElement.classList.remove('loader--is-visibility-hidden')
        //}, 250)

    }

    static hide() {

        document.body.classList.remove('no-scroll')
        loaderElement.classList.add('loader--is-visibility-hidden')
        //setTimeout(function() {
            loaderElement.classList.add('loader--is-hidden')
        //}, 250)

    }

}
