'use strict';

import $ from 'jquery';
import loader from '../loader/loader';
import modal from 'jquery-modal';

export default class Modal {

    constructor(params) {

	}

	init() {

        Modal.Init()

	}

    static Init() {

        let modalLinks = [].slice.call(document.querySelectorAll('[data-modal]'))

        if (!modalLinks.length) return false

        modalLinks.forEach(link => {
            link.addEventListener('click', Modal.modalLinkHandler);
        });

    }

    static modalLinkHandler(event) {

        event.preventDefault();

        $.ajax({
            url: event.target.getAttribute('href'),
            dataType: 'html',
            beforeSend: function() {
                loader.show();
            },
            success: function(res) {
                loader.hide();
                let modal = 0
                modal = $(res).appendTo('body').modal()
                modal.on($.modal.CLOSE, function(event, m) {
                    m.elm.remove()
                });
                Modal.Init();
            }
        })

    }

}
