'use strict';

import $ from 'jquery';

import Translator from '../_modules/translator/translator';

import { Forms } from './libs/form';

import Loader from '../_modules/loader/loader';
import Modal from '../_modules/modal/modal';

import Main from '../_modules/main/main';

(function(w, d) {

	function initTranslator() {
		const translator = new Translator();
		translator.init();
	}

	function initForms() {
		new Forms({ root: 'data-form' });
	}

	function initLoader() {
		const loader = new Loader();
		loader.init();
	}

	function initModalSettings() {
		$.modal.defaults = {
			closeExisting: true,
			escapeClose: false,
			clickClose: true,
			closeText: 'Закрыть',
			closeClass: '',
			showClose: true,
			modalClass: "modal",
			blockerClass: "jquery-modal",
			spinnerHtml: '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div>',
			showSpinner: true,
			fadeDuration: 250,
			fadeDelay: 1.0
		};
	}

	function initModal() {
		const modal = new Modal();
		modal.init();
	}

	function reInitModal() {
		$(document).on('modal:open', function(event, modal) {
			initForms();
		});
	}

	function initMain() {
		const main = new Main();
		main.init();
	}

	w.addEventListener('load', e => {

		initTranslator();

		initForms();

		initLoader();

		initModalSettings();
		initModal();
		reInitModal();

		initMain();

	});

}(window, document));
